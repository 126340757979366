<div class="flex flex-wrap gap-3">
    <div class="relative">
        <i class="pi pi-search absolute icon-search-input"></i>
        <input class="input-filters search-filter" [(ngModel)]="allFilters.search" type="text" placeholder="Búsqueda" (ngModelChange)="onFilter()"  pInputText>
    </div>
    <div *ngIf="filterPoint" class="relative">
        <i class="pi pi-filter absolute icon-search-input"></i>
        <p-dropdown
            class="dropdown-filter"
            [options]="pointSales"
            [filter]="true"
            optionLabel="nombre"
            filterBy="nombre"
            dataKey="id"
            optionValue="nombre"
            appendTo="body"
            [showClear]="true"
            [(ngModel)]="allFilters.pointSale"
            (onChange)="onFilter()"
            placeholder="Filtrar por punto de venta">
            <ng-template let-point pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div>{{ point.nombre }}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <div *ngIf="filterStatus" class="relative">
        <i class="pi pi-filter absolute icon-search-input"></i>
        <p-dropdown
            class="dropdown-filter search-filter"
            [options]="statuses"
            [filter]="true"
            optionLabel="name"
            filterBy="name"
            dataKey="id"
            optionValue="id"
            appendTo="body"
            [showClear]="true"
            [(ngModel)]="allFilters.state"
            (onChange)="onFilter()"
            placeholder="Estado">
            <ng-template let-status pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div>{{ status.name }}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <div *ngIf="filterCalendar" class="relative">
      <i class="pi pi-filter absolute icon-search-input"></i>
      <p-calendar
        [(ngModel)]="dateCalendar"
        class="calendar-filter"
        placeholder="Fecha de creación"
        (ngModelChange)="onSearchCalendar()"
      ></p-calendar>
    </div>
</div>
