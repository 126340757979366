import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TabMenuModule } from 'primeng/tabmenu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ImageModule } from 'primeng/image';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  declarations: [],
  exports: [
    CardModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    TableModule,
    ToastModule,
    TabMenuModule,
    BreadcrumbModule,
    MultiSelectModule,
    ToggleButtonModule,
    ImageModule,
    FileUploadModule,
    MessagesModule,
    InputSwitchModule,
    OverlayPanelModule
  ]
})
export class PrimengModule {}
