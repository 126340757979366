import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Menu } from '../../../core/interfaces/menu.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnChanges {

  @Input() menuItems!: Menu[];
  @Input() currentUrl: string = '';
  @Input() parentUrl: string = '';
  @Input() menuCollapsed: boolean = false;
  menuItem: boolean = true;

  constructor(
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['menuCollapsed']?.previousValue) {
      this.setMenuItem();
    }
  }

  goToModule(url: string) {
    this.router.navigate([`index/${url}`]);
  }

  doLogout() {
    localStorage.clear();
    this.router.navigate(['auth/login']);
  }

  changeViewMenu() {
    this.menuCollapsed = !this.menuCollapsed;
    this.setMenuItem();
  }

  setMenuItem() {
    if (!this.menuCollapsed) {
      setTimeout(() => {
        this.menuItem = true
      }, 200);
    } else {
      this.menuItem = false;
    }
  }

}
