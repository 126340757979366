import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { FormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
import { FilterModuleComponent } from './filter-module/filter-module.component';
import { LoadingComponent } from './loading/loading.component';

const exports: any = [
  MenuComponent,
  FilterModuleComponent,
  LoadingComponent
]

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  declarations: [exports],
  exports: exports
})
export class ComponentsModule {}
