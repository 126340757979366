import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'; 
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class PointOfSaleService {

  db = getFirestore(environment.firebaseConfig.appFirebase);

  constructor() { }

  async getPunto(id: string) {
    const q = query(collection(this.db, 'puntosDeVentaIntegracion'), where("id", "==", id))
    const querySnapshot = await getDocs(q)

    return querySnapshot
  }

  async getPuntos(role: string, code?: string) {
    const queryCollection = collection(this.db, 'puntosDeVentaIntegracion');
    let configQuery: any = []
    if (role === 'Distribuidor') {
      configQuery.push(where("distribuidor", "==", code));
    }
    let puntosQuery = query(queryCollection, ...configQuery);
    const puntosSnapshot = await getDocs(puntosQuery);
    const puntosList = puntosSnapshot.docs.map(doc => doc.data());
    return puntosList;
  }
}
