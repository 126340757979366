<div
    class="menu-container flex flex-column justify-content-between"
    [ngClass]="{'menu-container-collapsed': menuCollapsed}">
    <div>
        <div class="p-4">
            <img
                src="./assets/img/calypso-logo-renew.svg"
                alt="Logo Calypso"
                width="102"
                class="cursor-pointer"
                [ngClass]="{'img-menu': !menuCollapsed, 'img-menu-collapsed': menuCollapsed}"
                (click)="changeViewMenu()">
        </div>
        <ul class="menu-ul">
            <li class="cursor-pointer" *ngFor="let item of menuItems;" (click)="goToModule(item.url)">
                <div
                    class="list-menu-separator"
                    [ngClass]="{
                        'item-active': item.url && item.url === parentUrl,
                        'container-item flex align-items-center gap-3': !menuCollapsed,
                        'container-item-collapsed text-center': menuCollapsed
                    }">
                    <div>
                        <i class="pi {{item.icon}} font-icon-menu"></i>
                    </div>
                    <div *ngIf="!menuCollapsed && menuItem">
                        {{item.name}}
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div>
        <ul class="menu-ul">
            <li>
                <div
                    class="list-menu-separator"
                    [ngClass]="{
                        'container-item flex align-items-center gap-3': !menuCollapsed,
                        'container-item-collapsed text-center': menuCollapsed
                    }">
                    <div>
                        <i class="pi pi-bell font-icon-menu"></i>
                    </div>
                    <div *ngIf="!menuCollapsed && menuItem">
                        Notificaciones
                    </div>
                </div>
            </li>
            <li>
                <div
                    class="list-menu-separator"
                    [ngClass]="{
                        'container-item flex align-items-center gap-3': !menuCollapsed,
                        'container-item-collapsed text-center': menuCollapsed
                    }">
                    <div>
                        <i class="pi pi-user font-icon-menu"></i>
                    </div>
                    <div *ngIf="!menuCollapsed && menuItem">
                        Perfil
                    </div>
                </div>
            </li>
            <li class="cursor-pointer" (click)="doLogout()">
                <div
                    class="list-menu-separator logout-item"
                    [ngClass]="{
                        'container-item flex align-items-center gap-3': !menuCollapsed,
                        'container-item-collapsed text-center': menuCollapsed
                    }">
                    <div>
                        <i class="pi pi-sign-out font-icon-menu"></i>
                    </div>
                    <div *ngIf="!menuCollapsed && menuItem">
                        Cerrar sesión
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
