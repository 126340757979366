import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ls from 'localstorage-slim';
import { PointOfSaleService } from '../../../core/services/point-of-sale.service';
import { IFilter } from '@app/core/interfaces';
import { TableService } from '@app/core/services/table.service';

@Component({
  selector: 'app-filter-module',
  templateUrl: './filter-module.component.html',
  styleUrls: ['./filter-module.component.scss']
})
export class FilterModuleComponent implements OnInit {

  @Input() filterPoint: boolean = false;
  @Input() filterStatus: boolean = false;
  @Input() filterCalendar: boolean = false;
  @Output() filterRegisters = new EventEmitter<IFilter>();

  allFilters!: IFilter;

  pointSales: any;
  statuses: any = [
    {
      id: 'Pendiente',
      name: 'Pendiente'
    },
    {
      id: 'Pedido en curso',
      name: 'Pedido en curso'
    },
    {
      id: 'Pendiente por pesar',
      name: 'Pendiente por pesar'
    },
    {
      id: 'Entregado',
      name: 'Entregado'
    },
    {
      id: 'Cancelado',
      name: 'Cancelado'
    }
  ];

  searchText: string = ""
  pointSelected: any = {}
  statusSelected: any = {}
  userData: any = {};
  dateCalendar: string = '';

  constructor(
    private pointSaleService: PointOfSaleService,
    private tableService: TableService
  ) {}

  ngOnInit(): void {
    this.allFilters = this.tableService.getInitialFilters();
    this.userData = ls.get('usuario', {decrypt: true});
    if (this.filterPoint) {
      this.getPuntos();
    }
  }

  async getPuntos() {
    let code = '';
    if (this.userData?.tipo === 'Distribuidor') {
      code = this.userData?.distribuidor?.codigo;
    }
    this.pointSales = await this.pointSaleService.getPuntos(this.userData?.tipo, code);
  }

  onSearchCalendar() {
    let startDate = new Date(this.dateCalendar);
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(this.dateCalendar);
    endDate.setHours(23, 59, 59, 999);

    const filterCalendar = {
      startDate: startDate.getTime(),
      endDate: endDate.getTime()
    };
    this.allFilters.date = filterCalendar;
    this.filterRegisters.emit(this.allFilters);
  }

  onFilter() {
    this.filterRegisters.emit(this.allFilters);
  }
}
