import { Component } from '@angular/core';
import { UtilsService } from '@app/core/services/utils.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  isLoading = this.utilsService.isLoading;
  showModal: boolean = true;

  constructor(private readonly utilsService: UtilsService) {}

}
