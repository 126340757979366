import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Observable } from 'rxjs';
import { IPaginationData } from '../interfaces/pagination-data.interface';
import { IContentQueryConditions, IFilter } from '../interfaces';
import { environment } from '@app-environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  db = getFirestore(environment.firebaseConfig.appFirebase);
  allData: any = [];
  filteredData: any = [];

  constructor() {}

  setQueryBuilder(dataQuery: IContentQueryConditions) {
    switch (dataQuery.module) {
      case 'empresas':
        return [orderBy('fechaCreacion', 'desc')];
      case 'usuarios_b2b':
        return [
          where('idEmpresa', '==', dataQuery.enterpriseId),
          orderBy('createdAt', 'desc')
        ];
      case 'pedidos_b2b':
        return [
          where('B2BOrder', '==', true),
          where('usuario.idEmpresa', '==', dataQuery.enterpriseId),
          orderBy('createdAt', 'desc')
        ];
      case 'pedidos':
        return [
          this.setConditionsOrder(dataQuery.orderType!),
          orderBy('createdAt', 'desc')
        ];
    }
    return [orderBy('createdAt', 'desc')];
  }

  setConditionsOrder(orderType: string) {
    let conditionDetail: any;
    switch(orderType) {
      case 'b2b':
        conditionDetail = where('B2BOrder', '==', true);
    }
    return conditionDetail;
  }

  getInitialFilters(): IFilter {
    return {
      pointSale: '',
      state: '',
      date: undefined,
      search: '',
    };
  }

  getAllRegisters(collectionName: string, paginationData: IPaginationData, dataQuery: IContentQueryConditions): Observable<any> {
    const order = this.setQueryBuilder(dataQuery);
    const initialQuery = query(
      collection(this.db, collectionName),
      ...order
    );
    return new Observable((observer) => {
      onSnapshot(
        initialQuery,
        (querySnapshot) => {
          this.allData = querySnapshot.docs.map((doc) => doc.data());
          const processedData = this.getPaginationFiltered(false, collectionName, paginationData);
          observer.next(processedData);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  getPaginationFiltered(actionPaginate: boolean, collectionName: string, pagination: IPaginationData, filters?: IFilter) {
    if (!actionPaginate) { 
      let bulkData = this.allData.map((data: any) => ({...data}));
      if (filters) {
        if (filters.search.trim()) {
          const textSearch = filters.search.trim();
          const fieldsSearch = this.getMatchFields(collectionName);
          bulkData = this.filterByKeyword(bulkData, fieldsSearch, textSearch);
        }
        if (filters.pointSale) {
          bulkData = bulkData.filter((data: any) => data.puntoDeVentaNombre === filters.pointSale);
        }
        if (filters.state) {
          bulkData = bulkData.filter((data: any) => data.estado === filters.state);
        }
        if (filters.date) {
          if (filters.date.startDate > 0) {
            bulkData = bulkData.filter((data: any) => data.createdAt >= filters.date?.startDate! && data.createdAt <= filters.date?.endDate!);
          }
        }
      }
      this.filteredData = bulkData.map((data: any) => ({...data}));
    }
    const dataSize = this.filteredData.length;
    const data = this.filteredData.slice(
      (pagination.page - 1) * pagination.numberRows,
      pagination.page * pagination.numberRows
    );
    return { dataSize, data };
  }

  getMatchFields(collectionName: string) {
    let fields: string[] = [];
    switch(collectionName) {
      case 'empresas':
        fields = ['nombre', 'correo', 'nit', 'administrador'];
        break;
      case 'usuarios':
        fields = ['nombre', 'contacto', 'email', 'puntoDeVentaNombre', 'puntoDeVentaBodega'];
        break;
      case 'pedidos':
        fields = ['usuario.puntoDeVentaCiudad', 'puntoDeVentaNombre', 'direccion', 'usuario.email', 'usuario.celular'];
        break;
    }
    return fields;
  }

  filterByKeyword(data: any, fields: string[], text: string) {
    const filterDataResult = data.filter((row: any) => {
      return fields.some((field) => {
        let contentField = '';
        const fieldValue = field.split('.').reduce((o, p) => (o ? o[p] : undefined), row);
        if (fieldValue) {
          contentField = fieldValue.toLowerCase();
        }
        return contentField.includes(text.toLowerCase());
      });
    });
    return filterDataResult;
  }
}
